:root {
  --amplify-primary-color: #1f2631;
  --amplify-primary-tint: #0f73b5;
}

html,
body {
  height: 100vh;
}
amplify-authenticator {
  --background-color: #164d6c;
}
.card {
  max-width: 330px;
}
.main-logo {
  max-width: 600px;
  width: 100%;
  height: auto;
  padding-left: 50px;
  padding-right: 50px;
}
.main-logo-wrapper-signin {
  padding-top: 100px;
  margin-bottom: -100px;
}
.main-logo-wrapper {
  margin-top: 100px;
  margin-bottom: 75px;
}

/* Override collapsible */
.collapsible .number,
.token.number {
  background-color: inherit;
  border: none;
  height: inherit;
  padding: inherit;
  margin: inherit;
  font-size: inherit;
}
